import React from 'react';
import WhatsNewNCSACWHeroImg from "assets/images/hero-default.jpg";
import Layout from 'components/layout';
import { useStrapiWhatsNewData, useStrapiWhatsNewDataSeries } from 'hooks';
import {FlagCardContainer} from "components/containers/Cards"
import {Hero, Breadcrumb} from "components/ui";
import SeoComponent from 'components/seo';

const isBrowser = typeof window !== "undefined"

const WhatsNewNCSACW=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        // const {allStrapiResource}=useStrapiWhatsNewData();
        const { allStrapiSerie } = useStrapiWhatsNewDataSeries();
        const { allStrapiResource } = useStrapiWhatsNewData();
        const whatsNewPageData=[...allStrapiResource.nodes, ...allStrapiSerie.nodes ]
        // const whatsNewPageData=[...allStrapiSerie.nodes]
        const seo = {
            metaTitle: "What’s New on NCSACW",
            metaDescription: "Review the most recent resources posted on the NCSACW website. The resources are documents prepared by NCSACW or are relevant documents, reports, or websites about child welfare, substance use disorder, legal, or treatment court topics.",
            metaKeywords: "Tip sheet, plan, practice tip, resource guide, framework, report, child welfare, substance use disorder, legal, treatment court"
        }
        const sortWhatsNewResources=whatsNewResources=>{
            const whatsNewResourcesOrder=
            [
                "training-technical-assistance-resource-catalog",
                "trauma-toolkit-series",
                "ICWA-active-efforts-series",
                "cannabis-series",
                "safety-risk-guides-series",
                "harm-reduction-series",
            ]
            let sortedWhatsNewResources=whatsNewResources.sort((currentResource,resource)=>whatsNewResourcesOrder.indexOf(currentResource?.slug)-whatsNewResourcesOrder.indexOf(resource?.slug))
            return sortedWhatsNewResources;
          }

    return (
        <Layout location={location}>
            <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
            <Hero
            img={WhatsNewNCSACWHeroImg}
            color="#f5f5f5"
            isFixedHeight={true}
            >
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle}/>
                <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${seo.metaTitle}`}>{seo.metaTitle}</h1>
                <p>Access NCSACW’s latest materials designed to help organizations and professionals stay in the know about policy and practice strategies that support families in child welfare affected by substance use disorders.</p>
            </Hero>
             <div className="padding-y-5">
                <FlagCardContainer
                    key="whats-new"
                    resources={sortWhatsNewResources(whatsNewPageData)}
                    containerStyle="white-bg"
                    headerColor="text-base-lightest"
                    keyName="whats-new"
                    cardColor="bg-white"
                    grid="desktop:grid-col-12 min-height-flag-card-container card-detail card-mobile"
                    border="border-gray-10"
                    topicPage={false}
                />
            </div> 
        </Layout>
    )
}
export default WhatsNewNCSACW;


